import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import ProductList from "./components/ProductList"; // Import the ProductList component
import ProductPage from "./components/ProductPage"; // Import the ProductPage component

function App() {
  return (
    <Router>
      <Routes>
        
        <Route path="/:id" element={<ProductPage />} />
      </Routes>
    </Router>
  );
}

export default App;
