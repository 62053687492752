import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import products from "../data/products";
import HomeIcon from "@mui/icons-material/Home";

function RegistrationPage({ onRegister }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(phone)) {
      setIsPhoneInvalid(true); // Set error state
      //alert("Phone is Invalid. It should start with 6, 7, 8, or 9 and be exactly 10 digits long.");
      return;
    }

    if (!name) {
      alert("Please fill in all fields.");
      return;
    }

    // Add +91 to the phone number
    const formattedPhone = `+91${phone}`;

    // Proceed with registration
    onRegister({ name, phone: formattedPhone });
    
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.title}>Welcome to Our Showcase</h1>
        <p style={styles.subtitle}>Register to access our exclusive product feature.</p>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={styles.input}
          />
          <input
            type="number"
            placeholder="enter valid 10 digit number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            //style={styles.input}
            style={{
              ...styles.input,
              border: isPhoneInvalid ? "2px solid red" : "1px solid #ddd",
            }}
          />
           {/* Error message for invalid phone */}
           {isPhoneInvalid && (
            <p style={styles.errorMessage}>Please enter a valid 10 digit phone number</p>
          )}
          <button type="submit" style={styles.button}>
            Submit
          </button> 
        </form>
      </div>
    </div>
  );
}

const ProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const product = products.find((p) => p.id === id);
  // Function to navigate to external Home URL
  const handleHomeClick = () => {
    window.location.href = "#";
  }; 

  if (!user) {
    return <RegistrationPage onRegister={setUser} />;
  }

  if (!product) {
    return <h1>Product not found</h1>;
  }


  return (
    <div style={styles.pageContainer}>
      {/* Navbar */}
      <nav style={styles.navbar}>
        <div style={styles.navLeft}>
          <span style={styles.logo} >
          </span>
            <img
              src="/images/logoCoromass.png" // Replace with your logo path
              alt="Logo"
              style={styles.logoImage}
              onClick={() => handleHomeClick()}/>
            
        </div>
        <div style={styles.navRight}>
          <button style={styles.navButton} onClick={() => handleHomeClick()}>
          <HomeIcon style={{ marginTop: "2px", color:"black", fontSize:"30px" }} />
          </button>
          <select
            style={styles.dropdown}
            onChange={(e) => navigate(`/${e.target.value}`)}
            defaultValue=""
          >
            <option value="" disabled>
              Select Product
            </option>
            {products.map((p) => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
      </nav>

      {/* Product Page Content */}
      <div style={styles.contentContainer}>
        <div style={styles.box}>
          <div style={styles.imageContainer}>
            <img
              src={product.image}
              alt={product.name}
              style={styles.productImage}
            />
          </div>
        </div>
        <div style={styles.box}>
          <div style={styles.row}>
            <span style={styles.productId.productInfo}></span>
            <button style={styles.stockButton}>In Stock</button>
          </div>
          <div style={styles.row}>
            <h2 style={styles.productName}>{product.name}</h2>
          </div>
          <div style={styles.row}>
            <div style={styles.column}>
            <p style={styles.productInfo}>
                <strong  style={styles.productInfoStrong}>Technical Name:</strong> {product.technicalName}
              </p>
            <p style={styles.productInfo}>
                <strong  style={styles.productInfoStrong}>MarketedBy:</strong> {product.marketedBy}
              </p>
              <p style={styles.productInfo}>
                <strong  style={styles.productInfoStrong}>Brand:</strong> {product.brand}
              </p>
            </div>
          </div>
          {/* <div style={styles.row}>
            <button style={styles.buyButton}>
              Buy Now
            </button>
            <button style={styles.buyButton} onClick={() => handleHomeClick()}>
              Home
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

// Styles
  const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#f7f7f7",
    color: "#333",
    fontFamily: "'Poppins', sans-serif",
    padding: "20px",
    boxSizing: "border-box",
  },
  card: {
    background: "#fff",
    padding: "40px",
    borderRadius: "10px",
    boxShadow: "10px 8px 16px rgba(0, 0, 0, 1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
    marginBottom: "40px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "600",
    marginBottom: "16px",
    color: "#444",
  },
  subtitle: {
    fontSize: "16px",
    color: "#777",
    marginBottom: "24px",
    fontWeight: "600",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  input: {
    padding: "14px",
    fontSize: "16px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    outline: "none",
    transition: "box-shadow 0.2s",
    boxSizing: "border-box",
    width: "100%",
  },
  errorMessage: {
    color: "red",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
  },
  button: {
    padding: "14px",
    fontSize: "16px",
    fontWeight: "600",
    backgroundColor: "#10a37f",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.2s",
    width: "100%",
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
    paddingBottom: "20px",
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    marginTop: "10px",
    padding: "10px 20px",
    flexWrap: "wrap",
    backgroundColor: "#54b4d3",
    color: "#fff",
    borderRadius: "10px",
  },
  navLeft: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  logoImage: {
    height: "47px",
    marginRight: "10px",
  },
  logoText: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  navRight: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "10px",
    
  },
  navButton: {
    padding: "5px 10px",
    fontSize: "14px",
    backgroundColor:"#54b4d3",
    color: "#2563EB",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  dropdown: {
    padding: "5px",
    fontSize: "14px",
    border: "1px solid #ddd",
    borderRadius: "4px",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexWrap: "wrap",
    width: "90%",
    gap: "15px",
    marginTop: "20px",
  },
  box: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #ddd",
    borderRadius: "15px",
    backgroundColor: "#fff",
    padding: "15px",
    minHeight: "350px",
    maxHeight: "350px",
    maxWidth: "100%",
    flexBasis: "45%",
  },
  imageContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  productImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  row: {
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  column: {
    flex: 1,
    minWidth: "45%",
    padding: "5px",
  },
  productName: {
    fontSize: "26px",
    fontWeight: "700",
    color: "#333",
    marginBottom: "12px",
  },
  productInfo: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "12px",
    lineHeight: "1.6",
  },
  productInfoStrong: {
    fontWeight: "600",
    color: "#10a37f",
  },
  productId: {
    fontSize: "14px",
    color: "#555",
  },
  stockButton: {
    padding: "5px 10px",
    fontSize: "12px",
    backgroundColor: "#10B981",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  buyButton: {
    padding: "8px 20px",
    fontSize: "14px",
    backgroundColor: "#F36F21",
    color: "#fff",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
  },
  footer: {
    marginTop: "20px",
    textAlign: "center",
    fontSize: "14px",
    color: "#888",
  },

  // Media Queries
  "@media (max-width: 768px)": {
    card: {
      padding: "20px",
    },
    title: {
      fontSize: "24px",
    },
    contentContainer: {
      flexDirection: "column",
      gap: "10px",
    },
    box: {
      flexBasis: "100%",
    },
  },
  "@media (max-width: 480px)": {
    navbar: {
      flexDirection: "column",
    },
    navRight: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    card: {
      padding: "15px",
    },
    title: {
      fontSize: "20px",
    },
    subtitle: {
      fontSize: "14px",
    },
  },
};



export default ProductPage;