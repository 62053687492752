const products = [
    {
      id: "onset",
      name: "ONSET",
      image: "/images/amine salt.png",
      marketedBy: "Coromass Crop Care Industries",
      technicalName: "2,4-D Amine Salt 58% SL",
      brand: "ONSET",
    },
    {
      id: "cower",
      name: "COWER",
      image: "/images/Atrazine (Cower).png",
      marketedBy: "Coromass Crop Care Industries",
      technicalName: "Atrazine 50% WP",
      brand: "COWER",
    },
      {
        id: "pious",
        name: "PIOUS",
        image: "/images/Bifen 10 (PIOUS).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Bifenthrin 10% EC",
        brand: "PIOUS",
      },
      {
        id: "wont",
        name: "WONT",
        image: "/images/sticker.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Carbofuran 3% CG",
        brand: "WONT",
      },
      {
        id: "corodan4G",
        name: "CORODAN-4G",
        image: "/images/cartap.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Cartap Hydrochloride 4% Gr.",
        brand: "COROD AN -4G",
      },
      {
        id: "zoty",
        name: "ZOTY",
        image: "/images/Carben+Manco (Zoty).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Cartap Hydrochloride 50% SP",
        brand: "ZOTY",
      },
      {
        id: "exaltGr",
        name: "EXALT GR",
        image: "/images/ctpr gr.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Chlorantraniliprole 18.5% SC",
        brand: "EXALT",
      },
      {
        id: "exalt",
        name: "EXALT",
        image: "/images/ctpr sc.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "CHLORANTRANILIPROLE 18.5% sc",
        brand: "EXALT",
      },
      {
        id: "cropgold",
        name: "CROP GOLD",
        image: "/images/amino acid (Coro Gold).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Amino Acid based all purpose water soluable plant food",
        brand: "CROP GOLD",
      },
      {
        id: "glostertop",
        name: "GLOSTER TOP",
        image: "/images/Azoxy+Difen (Gloster Top).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "AZOXYSTROBIN 1820/0 DIFENOCONAZOLE II .40/0 WAN SC",
        brand: "GLOSTER TOP",
      },
      {
        id: "coax",
        name: "COAX",
        image: "/images/clodino.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Technical Name for SodiumClodinafop-Propargyl WP",
        brand: "COAX",
      },
      {
        id: "coroGr",
        name: "CORO GR",
        image: "/images/cpp gr.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "CHLORPYRIPHOS 100/0 GR",
        brand: "CORO GR",
      },
      {
        id: "koro-20",
        name: "KORO-20",
        image: "/images/CPP-20 (Koro-20).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "CHLORPYRIPHOS 200/0",
        brand: "KORO 20",
      },
      {
        id: "koro-50",
        name: "KORO-50",
        image: "/images/CPP-50 (Koro-50).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Technical Name for Sodium",
        brand: "KORO 50",
      },
      {
        id: "team505",
        name: "TEAN-505",
        image: "/images/cpp+cyper.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "CHLORPYRIPHOS 50% + CYPERMETHRIN EC",
        brand: "TEAM 505",
      },
      {
        id: "exaltxtra",
        name: "EXALT XTRA",
        image: "/images/CTPR+Lambda (Exalt Xtra).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "CHLORANTRANILIPROLE LAMBDACYHALOTHRIN 4.6% ZC",
        brand: "EXALT XTRA",
      },
      {
        id: "zetway",
        name: "ZETWAY",
        image: "/images/Cymox+Manco1.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "CYMOXANIL 80/0 + MANCOZEB 640/0 wp",
        brand: "ZETWAY",
      },
      {
        id: "niftysuper",
        name: "NIFTY SUPER",
        image: "/images/Ema 1.9 Alu Bottle (Nifty Super).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "EMAMECTIN BENZOATE 1.9% EC",
        brand: "NIFTY SUPER",
      },
      {
        id: "rotort",
        name: "ROTORT",
        image: "/images/dinetufuron.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "DINOTEFURAN 200/0",
        brand: "ROTORT",
      },
      {
        id: "nifty",
        name: "NIFTY",
        image: "/images/emamectin.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "EMAMECTIN BENZOATE 50/0 SG",
        brand: "NIFTY",
      },
      {
        id: "fatom",
        name: "FATOM",
        image: "/images/azoxy+manco.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "AZOXYSTROBIN 1 1.50//0 + MANCOZEB WW WP",
        brand: "FATOM",
      },
      {
        id: "ardent",
        name: "ARDENT",
        image: "/images/Azoxy+Tebu (Ardent).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "AZOXYSTROBIN 1 1.50//0 + MANCOZEB WW WP",
        brand: "ARDENT",
      },
      {
        id: "tremor",
        name: "TREMOR",
        image: "/images/pendi 30.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PENDIMETHALIN 300/0 EC HERBICIDE",
        brand: "TREMOR",
      },
      {
        id: "docile",
        name: "DOCILE",
        image: "/images/fipro 0.3.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "FIPRONIL 0.3% GR Insecticide",
        brand: "DOCILE",
      },
      {
        id: "bulwark",
        name: "BULWARK",
        image: "/images/FIPRONIL 0.6.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "FIPRONIL wm GR Insecticide",
        brand: "BULWARK",
      },
      {
        id: "quickgrow",
        name: "QUICKGROW",
        image: "/images/gibberlic (Peptech).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "GIBBERELLIC ACID LPlant Growth Regulator",
        brand: "QUICKGROW",
      },
      {
        id: "tract",
        name: "TRACT",
        image: "/images/glufo.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "GLUFOSINATE AMMONIUM 13.5 0/0 Wm SL",
        brand: "TRACT",
      },
      {
        id: "prplus",
        name: "PR PLUS",
        image: "/images/dinetu+pymetro.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "DINOTEFURAN + PYMETROZINE WG",
        brand: "PR PLUS",
      },
      {
        id: "hexagrow",
        name: "HEXAGROW",
        image: "/images/Hexa SC-1.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "HEXACONAZOLE 50/0 SC Systemic Fungicide",
        brand: "HEXAGROW",
      },
      {
        id: "koroblack",
        name: "KORO BLACK",
        image: "/images/humic flakes (Koro Black).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "KORO BLACK ORGANIC PRODUCT",
        brand: "KORO BLACK",
      },
      {
        id: "horde",
        name: "HORDE",
        image: "/images/imida 30.5.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Imidacloprid 30.5% SC Insecticide",
        brand: "HORDE",
      },
      {
        id: "gentry",
        name: "GENTRY",
        image: "/images/imida gr.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "IMIDACLOPRID GR Systemic Insecticide",
        brand: "GENTRY",
      },
      {
        id: "yield",
        name: "YIELD",
        image: "/images/indoxa.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "INDOXACARB SC",
        brand: "YIELD",
      },
      {
        id: "coro One",
        name: "CORO ONE",
        image: "/images/Isopro.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "ISOPROTHIOLANE 400/0 EC",
        brand: "CORO ONE",
      },
      {
        id: "seek",
        name: "SEEK",
        image: "/images/Lambda 5 (SEEK).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "EMAMECTIN BENZOATE 1.9% EC",
        brand: "SEEK",
      },
      {
        id: "seekplus",
        name: "SEEK PLUS",
        image: "/images/lamda cs.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "LAMBADA CYHALOTHRIN 49% CS INSECTICIDE",
        brand: "SEEK PLUS",
      },
      {
        id: "grinm-45",
        name: "GRIN M-45",
        image: "/images/m-45.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "MANCOZEB 750/0 WP Contact Fungicide",
        brand: "GRIN M-45",
      },
      {
        id: "rapt",
        name: "RAPT",
        image: "/images/mangenese.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "MANGANESE SULPHATE 30.5% Agriculture Grade",
        brand: "RAPT",
      },
      {
        id: "m-treee",
        name: "M-TREE",
        image: "/images/Metribuzin.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "METRIBUZIN 700/0 WP Herbicide",
        brand: "M-TREE",
      },
      {
        id: "stake",
        name: "STAKE",
        image: "/images/metsulfuron.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "METSULFURON METHYL 200/0 WP",
        brand: "STAKE",
      },
      {
        id: "stelto",
        name: "STELTO",
        image: "/images/mycorhhiza.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "MYCORRHIZAL BIO-FERTILIZER",
        brand: "STELTO",
      },
      {
        id: "rubel",
        name: "RUBEL",
        image: "/images/nova+ema.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "NOVALURON + 5.25% EMAMECTIN BENZOATE 0.9% W/N SC",
        brand: "RUBEL",
      },
      {
        id: "brisk",
        name: "BRISK",
        image: "/images/paraquat.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PARAQUAT DICHLORIDE 24% SL",
        brand: "BRISK",
      },
      {
        id: "trehor",
        name: "TREHOR",
        image: "/images/pendi 30.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PENDIMETHALIN 30% EC",
        brand: "TREHOR",
      },
      {
        id: "deft",
        name: "DEFT",
        image: "/images/pretila ec.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PRETILACHLOR 50 % EC",
        brand: "DEFT",
      },
      {
        id: "deftplus",
        name: "DEFT PLUS",
        image: "/images/pretila ew.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PRETILACHLOR 37% W/W EW",
        brand: "DEFT PLUS",
      },
      {
        id: "brook",
        name: "BROOK",
        image: "/images/Pro+Cyper.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Profenofos 40%+ Cypermethrin 4% EC",
        brand: "BROOK",
      },
      {
        id: "ally",
        name: "ALLY",
        image: "/images/propi.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PROPICONAZOLE 25 % EC",
        brand: "ALLY",
      },
      {
        id: "arcne",
        name: "ARCNE",
        image: "/images/propineb.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PROPINEB WP",
        brand: "ARCNE",
      },
      {
        id: "astute",
        name: "ASTUT",
        image: "/images/pymetro.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PYMETROZINE 500/0 WG",
        brand: "ASTUT",
      },
      {
        id: "salient",
        name: "SALIENT",
        image: "/images/pyrazo.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PYRAZOSULFURON wp Systemic Herbicide",
        brand: "SALIENT",
      },
      {
        id: "erapro",
        name: "ERA PRO",
        image: "/images/Pyroxasulfone Wg.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "PYROXASULFONE 850/0 WG HERBICIDE",
        brand: "ERA PRO",
      },
      {
        id: "wont",
        name: "WONT",
        image: "/images/sticker.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Rapid Penetrating & Spreading Agent",
        brand: "WONT",
      },
      {
        id: "corosulf",
        name: "COROSULF",
        image: "/images/sulfo.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Sulfosulfuron 75% WG",
        brand: "COROSULF",
      },
      {
        id: "canto",
        name: "CANTO",
        image: "/images/sulfo+met.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "SUIFOSUIFURON METSUIFURON METHYL 5% WG",
        brand: "CANTO",
      },
      {
        id: "corosole",
        name: "COROSOLE",
        image: "/images/sulphur 80.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "SULPHUR 800/0 WDG Contact Fungicide",
        brand: "COROSOLE",
      },
      {
        id: "racle",
        name: "RACLE",
        image: "/images/tebu ds.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "TEBUCONAZOLE 20/0 DS Systemic Fungicide",
        brand: "RACLE",
      },
      {
        id: "yarnFungicide",
        name: "YARN",
        image: "/images/tebu ec.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "TEBUCONAZOLE SC Fungicide",
        brand: "YARN",
      },
      {
        id: "yarnSystemicFungicide",
        name: "YARN",
        image: "/images/tebu SC.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "TEBUCONAZOLE 20/0 DS Systemic Fungicide",
        brand: "YARN",
      },
      {
        id: "visage",
        name: "VISAGE",
        image: "/images/tebu+captan.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "TEBUCONAZOLE CAPTAN 269 % WW SC",
        brand: "VISAGE",
      },
      {
        id: "amtara",
        name: "AMTARA",
        image: "/images/Thia 25 WG (Amtara).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "THIAMETHOXAM 250/0 WG INSECTICIDE",
        brand: "AMTARA",
      },
      {
        id: "amtara-FS",
        name: "AMTARA-FS",
        image: "/images/Thia FS (AMTARA-FS).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "THIAMETHOXAM 300/0 FS Systemic Insecticide",
        brand: "ATARA-FS",
      },
      {
        id: "rister",
        name: "RISTER",
        image: "/images/thiafluzamide.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "THIFLUZAMIDE 24% SC Systemic Fungicide",
        brand: "RISTER",
      },{
        id: "tactic",
        name: "TACTIC",
        image: "/images/Thiophanate.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "THIOPHANATE METHYL 70 % WP",
        brand: "TACTIC",
      },
      {
        id: "accord",
        name: "ACCORD",
        image: "/images/Triacontanol EW (Accord).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "TRIACONTANOL EW .1% MIN. Plant Growth Regulator",
        brand: "ACCORD",
      },
      {
        id: "accordgr",
        name: "ACCORD GR",
        image: "/images/triacontanol gr.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "TRIACONTANOL GR 0.05% MIN. Plant Growth Regulator",
        brand: "ACCORD GR",
      },
      {
        id: "retort",
        name: "RETORT",
        image: "/images/tricoderma.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "Trichoderma Viride 1.5% W.P.",
        brand: "RETORT",
      },
      {
        id: "v-shine",
        name: "V-SHINE",
        image: "/images/Valida (V-SHINE).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "VALIDAMYCIN 3% L Systemic Antibiotic",
        brand: "V-SHINE",
      },
      {
        id: "quickzinc33",
        name: "QUICK ZINK 33",
        image: "/images/zinc 33.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "ZINC SULPHATE MONOHYDRATE",
        brand: "QUICK ZINK 33",
      },
      {
        id: "render12",
        name: "RENDER-12",
        image: "/images/zinc edta.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "CHELATED ZINC AS Zn-EDTA 12%",
        brand: "RENDER-12",
      },
      {
        id: "quickzink33",
        name: "QUICK ZINK 39.5 %",
        image: "/images/Zinc Oxide (Quick Zinc).png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "ZINC OXIDE 39.5% SC",
        brand: "QUICK ZINK 39.5",
      },
      {
        id: "quickzink39.5",
        name: "QUICK ZINK 39.5 %",
        image: "/images/zinc oxide.png",
        marketedBy: "Coromass Crop Care Industries",
        technicalName: "ZINC OXIDE 39.5% SC",
        brand: "QUICK ZINK 39.5",
      },
      
    // Add 67+ products here
  ];
  
  export default products;
  